import './App.css';
import { Amplify, Auth } from "aws-amplify";
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {
  Admin,
  Resource,
  Title,
  useStore,
  Layout
} from "react-admin";
import { buildAuthProvider, buildDataProvider } from "./providers";
import en from 'ra-language-english';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import config from './aws-exports';
import {
  CognitoGroupList,
  CognitoUserList,
  CognitoUserShow,
} from "react-admin-amplify";
import * as generatedMutations from "./graphql/mutations";
import * as customMutations from "./graphql/custom-mutations";
import * as generatedQueries from "./graphql/queries";
import * as customQueries from "./graphql/custom-queries";
import { TraineeList, TraineeCreate, TraineeShow, TraineeEdit, TraineeEditUserView } from "./components/Trainee"
import { AdminDepartmentsList, TraineeDepartmentsList, DepartmentsCreate, DepartmentsEdit, DepartmentShow } from "./components/Department"
import { ManyPreferenceCreate, PreferenceCreate, PreferenceEdit, PreferenceShow, PreferenceList } from "./components/Preference"
import { RotationsList, RotationsCreate, RotationsEdit, formatDate } from "./components/Rotation"
import { SitesList, SitesCreate, SitesEdit } from "./components/Sites";
import { TraineeCommentsList, TraineeCommentsCreate, TraineeCommentsEdit } from "./components/TraineeComments";
import {GroupsList, GroupsCreate, GroupsEdit} from "./components/Groups";
import { SeatsShow, SeatCreate, SeatList, SeatEdit, UserSeatEdit } from "./components/Seat"
import { Dashboard } from './components/Dashboard';
import { authenticatorComponents, services, formFields } from './components/authenticatorComponents'
import { DepartmentStatisticsList } from './components/DepartmentStatistics'
import { OptiSeatAppBar } from './components/AppBar';
import { Theme } from './components/theme'
Amplify.configure(config);

const translations = { en };

export const i18nProvider = polyglotI18nProvider(
  locale => translations[locale],
  'en', // default locale
  [{ locale: 'en', name: 'English' }],
);
const authProvider = buildAuthProvider()
// Get the demo user avatar
authProvider.getIdentity = async () => {
  try {
    const currentUserData = await Auth.currentUserInfo()

    return {
      'id': currentUserData.attributes.sub,
      'fullName': currentUserData.attributes.name,
      'email': currentUserData.attributes.email,
      'avatar': null
    };
  } catch (e) {
    console.log(e);
  }
};

const CustomLayout = (props) => <Layout {...props} appBar={OptiSeatAppBar} />;


function App() {
  const queries = { ...generatedQueries };

  for (const key in customQueries) {
    if (Object.prototype.hasOwnProperty.call(customQueries, key)) {
      queries[key] = customQueries[key];
    }
  }

  const mutations = { ...generatedMutations };

  for (const key in customMutations) {
    if (Object.prototype.hasOwnProperty.call(customMutations, key)) {
      queries[key] = customMutations[key];
    }
  }
  return (
    <div
      style={{
        backgroundColor: '#11111f',
        height: '100vh',
        minHeight: '100vh',
      }}
    >
      <Authenticator signUpAttributes={[
        'email',
        'name',
      ]}
        hideSignUp
        components={authenticatorComponents}
        services={services}
        formFields={formFields}
      >
        <Admin
          theme={Theme}
          authProvider={authProvider}
          // loginPage={false}
          dataProvider={buildDataProvider({ queries, mutations }, {
            enableAdminQueries: true
          })}
          i18nProvider={i18nProvider}
          dashboard={() => (<Dashboard />)}
          layout={CustomLayout}
        >{(permissions) =>
        (
          <>
            <Resource
              name="trainees"
              options={{ label: "Current Rotation" }}
              list={permissions?.includes("Admins") && TraineeList}
              create={permissions?.includes("Admins") && TraineeCreate}
              edit={permissions?.includes("Admins") ? TraineeEdit : TraineeEditUserView}
              show={TraineeShow}
              recordRepresentation="name"
            />
            <Resource
              name="departments"
              options={{ label: "Departments" }}
              list={permissions?.includes("Admins") ? AdminDepartmentsList : TraineeDepartmentsList}
              create={permissions?.includes("Admins") && DepartmentsCreate}
              edit={permissions?.includes("Admins") && DepartmentsEdit}
              show={DepartmentShow}
              recordRepresentation="name"
            />
            <Resource
              name="groups"
              options={{ label: "Groups" }}
              list={permissions?.includes("Admins") && GroupsList }
              create={permissions?.includes("Admins") && GroupsCreate}
              edit={permissions?.includes("Admins") && GroupsEdit}
              recordRepresentation="name"
            />
            <Resource
              name="preferences"
              create={permissions?.includes("SuperAdmins") ? PreferenceCreate : ManyPreferenceCreate}
              show={PreferenceShow}
              edit={permissions?.includes("Admins") && PreferenceEdit}
              list={permissions?.includes("SuperAdmins") && PreferenceList}
              recordRepresentation="traineeID"
            />
            <Resource
              name="rotations"
              options={{ label: 'Cohorts' }}
              list={permissions?.includes("SuperAdmins") && RotationsList}
              edit={permissions?.includes("Admins") && RotationsEdit}
              create={permissions?.includes("Admins") && RotationsCreate}
              recordRepresentation={record => formatDate(record.startDate)}
            />
            <Resource
              name="seats"
              show={SeatsShow}
              create={permissions?.includes("SuperAdmins") && SeatCreate}
              list={permissions?.includes("SuperAdmins") && SeatList}
              edit={permissions?.includes("SuperAdmins") && SeatEdit}
            />
            <Resource
              name="referenceSeats"
              show={SeatsShow}
              create={permissions?.includes("SuperAdmins") && SeatCreate}
              list={permissions?.includes("SuperAdmins") && SeatList}
              edit={permissions?.includes("SuperAdmins") && SeatEdit}
            />
            <Resource
              name="publishedSeats"
              create={permissions?.includes("SuperAdmins") && SeatCreate}
              list={permissions?.includes("SuperAdmins") && SeatList}
              edit={permissions?.includes("Admins") ? SeatEdit : UserSeatEdit}
            />
            <Resource
              name="departmentStatistics"
            />
            <Resource
              name="sites"
              list={permissions?.includes("SuperAdmins") && SitesList}
              edit={permissions?.includes("SuperAdmins") && SitesEdit}
              create={permissions?.includes("SuperAdmins") && SitesCreate}
              recordRepresentation="name"
            />
            <Resource
            name="traineeComments"
            list={permissions?.includes("SuperAdmins") && TraineeCommentsList}
            edit={permissions?.includes("SuperAdmins") && TraineeCommentsEdit}
            create={permissions?.includes("SuperAdmins") && TraineeCommentsCreate}
            />
          </>
        )}
        </Admin>
      </Authenticator>
    </div>
  )
}

export default App;
