/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://w8rmbj66ug.execute-api.eu-west-2.amazonaws.com/freshfield",
            "region": "eu-west-2"
        },
        {
            "name": "assignSeats",
            "endpoint": "https://9pdab4l0z3.execute-api.eu-west-2.amazonaws.com/freshfield",
            "region": "eu-west-2"
        },
        {
            "name": "publishSeats",
            "endpoint": "https://ovqc0nleze.execute-api.eu-west-2.amazonaws.com/freshfield",
            "region": "eu-west-2"
        },
        {
            "name": "referenceSeats",
            "endpoint": "https://k8glakgu1f.execute-api.eu-west-2.amazonaws.com/freshfield",
            "region": "eu-west-2"
        },
        {
            "name": "reminderEmail",
            "endpoint": "https://oet0hdc3sj.execute-api.eu-west-2.amazonaws.com/freshfield",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://t2qltucfrbh7zm7leuxml2e5q4.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-2:e4735831-6154-4663-a8db-c0f13537fb4a",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_qJuiLXNl8",
    "aws_user_pools_web_client_id": "4d481a3qftagt6qnriqilm1k3n",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "optiseatb36133a8b081486898e91560fcd6852c115503-freshfield",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
