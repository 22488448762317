
export const is_preference_white_text = (priority) => {
    if (process.env.REACT_APP_NUM_OF_PREFRENCES == 4) {
        return priority >= 4
    } else if (process.env.REACT_APP_NUM_OF_PREFRENCES == 6) {
        return priority >= 4
    } else if (process.env.REACT_APP_NUM_OF_PREFRENCES == 3) {
        return priority >= 3
    }else if (process.env.REACT_APP_NUM_OF_PREFRENCES == 7) {
        return priority >= 4
    }

}

export const get_color_code = (priority) => {
    if (process.env.REACT_APP_NUM_OF_PREFRENCES == 4) {
        const colors = ['#50C878', '#FAC898', '#E97451', '#721817', '#11111f']
        return colors[priority - 1]
    } else if (process.env.REACT_APP_NUM_OF_PREFRENCES == 6) {
        const colors = ['#50C878', '#F8F171', '#E97451', '#721817', '#854791', '#2440BC', '#11111f']
        return colors[priority - 1]
    } else if (process.env.REACT_APP_NUM_OF_PREFRENCES == 7) {
        const colors = ['#50C878', '#F8F171', '#E97451', '#721817', '#854791', '#2440BC', '#2440BC', '#11111f']
        return colors[priority - 1]
    } else if (process.env.REACT_APP_NUM_OF_PREFRENCES == 3) {
        const colors = ['#50C878', '#FAC898', '#E97451', '#11111f']
        return colors[priority - 1]
    }
}

export const get_num_of_preferences = (priority, currentRotation) => {
    switch (priority) {
        case 1:
            return currentRotation?.numberOfFirstPreferences
        case 2:
            return currentRotation?.numberOfSecondPreferences
        case 3:
            return currentRotation?.numberOfThirdPreferences
        case 4:
            return currentRotation?.numberOfFourthPreferences
        case 5:
            return currentRotation?.numberOfFifthPreferences
        case 6:
            return currentRotation?.numberOfSixthPreferences
        case 7:
            return currentRotation?.numberOfSeventhPreferences
        case 8:
            return currentRotation?.numberOfEighthPreferences
        case 0:
            return currentRotation?.numberOfNoPreferences
    }
}

export const ordinal_suffix_of = (i) => {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}

export const wordy_ordinal = (i) => {
    const wordy_ordinals = ["zeroth", "first", "second", "third", "fourth", "fifth", "sixth", 'seventh', 'eighth']
    return wordy_ordinals[i]
}